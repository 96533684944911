import { types } from 'mobx-state-tree';

import AuthStore from '@yaydoo/react-mobx-stores/lib/auth';
import UserStore from '@yaydoo/react-mobx-stores/lib/user';
import Business, { PhoneModel } from '@yaydoo/react-mobx-stores/lib/business';
import Address from '@yaydoo/react-mobx-stores/lib/address';
import AccessStore, { TAccessStore } from '@yaydoo/react-mobx-stores/lib/access';
import { AddressType } from '@yaydoo/react-mobx-stores/lib/address/enums';

import CreditBalanceStore, {
  CommonCreditStoreType,
} from '@yaydoo/react-mobx-stores/lib/creditCommon';

import AccountStore, { TAccountStore } from '@yaydoo/react-mobx-stores/lib/account';

import TestStore, { TTestStore } from './testStore';

import {
  GlobalStore as BaseGlobalStore,
  IGlobalStore as IBaseGlobalStore,
} from '@yaydoo/react-mobx-stores/lib/globalStore';

import StoreResolver from '@yaydoo/react-mobx-stores/lib/storeResolver';

export interface IGlobalStore extends IBaseGlobalStore {
  testStore: TTestStore;
  //remove this later
  creditCommon: CommonCreditStoreType;
  account: TAccountStore;
  //ROLES & PERMISSIONS
  access: TAccessStore;
}

export const GlobalStore = types.compose(
  BaseGlobalStore,
  types.model({
    // add project stores here
    creditCommon: CreditBalanceStore,
    testStore: TestStore,
    account: AccountStore,
    //ROLES & PERMISSIONS
    access: AccessStore,
  }),
);

export const store: IGlobalStore = GlobalStore.create({
  creditCommon: CreditBalanceStore.create({}),
  testStore: TestStore.create({}),
  auth: AuthStore.create({}),
  account: AccountStore.create({}),
  user: UserStore.create({
    phone: PhoneModel.create({}),
  }),
  business: Business.create({
    businessAddress: Address.create({
      type: AddressType.LEGAL,
    }),
    personalAddress: Address.create({
      type: AddressType.PERSONAL,
    }),
  }),
  //ROLES & PERMISSIONS
  access: AccessStore.create({}),
});

StoreResolver.bind(store);
export default store;
