import { FC } from 'react';

import RedirectTo from '@yaydoo/react.ui-access-manager/lib/access/redirectTo';
import useHasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/useHasActionAccess';
import useHasFinancialInstrumentAccess from '@yaydoo/react.ui-access-manager/lib/access/useHasFinancialInstrumentAccess';

export interface IAuthRoute {
  allowedAction?: string;
  allowedFinancialInstrument?: string;
}

const AuthRoute: FC<IAuthRoute> = ({ children, allowedAction, allowedFinancialInstrument }) => {
  const actionValidation = useHasActionAccess({ action: allowedAction });
  const financialInstrumentValidation = useHasFinancialInstrumentAccess({
    financialInstrument: allowedFinancialInstrument,
  });

  const needsFinancialInstrumentValidation = allowedFinancialInstrument ? true : false;
  const financialInstrumentAccess =
    !needsFinancialInstrumentValidation || financialInstrumentValidation;

  const needsActionAccessValidation = allowedAction ? true : false;
  const actionAccess = !needsActionAccessValidation || actionValidation;

  if (actionAccess && financialInstrumentAccess) {
    return <>{children}</>;
  }
  return <RedirectTo pathName="/" />;
};

export default AuthRoute;
