import BATCH_ROUTES from '@yaydoo/react.batch-hub-app/lib/src/constants/routes';
import COLLECT_ROUTES from '@yaydoo/react.collect-hub-app/lib/src/constants/routes';
import TOOLS_ROUTES from '@yaydoo/react.payment-tools/lib/src/constants/routes';
import MOVEMENT_ROUTES from '@yaydoo/react.payment-transactions-hub-app/lib/src/constants/routes';
import RECONCILIATION_ROUTES from '@yaydoo/react.reconciliation-hub-app/lib/src/constants/routes';

export const USER = {
  USER_ROOT: '/user',
  PROFILE: '/user/profile',
};

export default {
  ...USER,
  LANDING: '/',
  LOGIN: '/login',
  TEST: '/test',
  TOOLS: TOOLS_ROUTES.TOOLS,
  COLLECT: COLLECT_ROUTES.COLLECT,
  MOVEMENTS: MOVEMENT_ROUTES,
  RECONCILIATION: RECONCILIATION_ROUTES.REPORTS,
  BATCH_ROUTES: BATCH_ROUTES,
};
