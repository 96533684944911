import { useCallback, useState } from 'react';

import useStore from '@hooks/useStore';
import { IPermissions } from '@hooks/useApp';

export interface IUseGetUserData {
  loadUserData: (permissions: IPermissions) => void;
  dataFetched: boolean;
}

function useGetUserData(): IUseGetUserData {
  const [dataFetched, setDataFetched] = useState(false);
  const { user, business, account } = useStore();

  const loadUserData = useCallback(async (permissions) => {
    try {
      await Promise.all([
        // stores that use volatile state
        business.updateService(),
      ]);

      const calls = [user.getDetails(), business.getBusiness()];

      if (permissions.canFetchBalance) calls.push(account.getBalance());

      if (permissions.canFetchClabe) calls.push(account.getClabe());

      if (permissions.isAdmin) calls.push(business.getAddresses());

      await Promise.all(calls);
    } catch {
      // empty catch for now
    } finally {
      setDataFetched(true);
    }
  }, []);

  return { loadUserData, dataFetched };
}

export default useGetUserData;
