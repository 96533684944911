import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Variables from '@yaydoo/react-components/lib/utils/Variables';
import AppLoader from '@yaydoo/react-components/lib/components/AppLoaders/Icons';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';
import { allowedRoles } from '@yaydoo/react.ui-access-manager/lib/constants/allowedRoles';

import routes from '@constants/routes';
import useApp from '@hooks/useApp';
import useStore from '@hooks/useStore';
import useGetUserAccess from '@hooks/useGetUserAccess';
import AuthRoute from '@components/AuthRoute';
import HomePage from '@components/Home';

import { DashboardPages, BADocumentUpload } from '@yaydoo/react.dashboard-hub-app/lib/src/pages';

import Batch from '@yaydoo/react.batch-hub-app/lib/src/pages';
import Collect from '@yaydoo/react.collect-hub-app/lib/src/pages';
import Tools from '@yaydoo/react.payment-tools/lib/src/pages';
import Movements from '@yaydoo/react.payment-transactions-hub-app/lib/src/pages';
import Reconciliation from '@yaydoo/react.reconciliation-hub-app/lib/src/pages';
import UserManagement from '@yaydoo/react.user-management-hub-app/lib/src/pages';
import { allowedFinancialInstruments } from '@yaydoo/react.ui-access-manager/lib/constants/allowedFinancialInstruments';

import TagManager from 'react-gtm-module';

export interface IRouter {
  areEnviromentVarsLoaded: boolean;
  variables: Variables;
}

const Router: FC<IRouter> = ({ areEnviromentVarsLoaded, variables }) => {
  const history = useHistory();
  const {
    access: { userRole, hasAccessActions },
  } = useStore();
  const isAdmin = userRole === allowedRoles.ADMIN || userRole.includes(allowedRoles.ADMIN);
  const canFetchBalance = hasAccessActions(allowedActions.DEBIT_ACCOUNT_VIEW_BALANCE);
  const canFetchClabe = hasAccessActions(allowedActions.ACCOUNTS_SETTINGS_VIEW_BANK_INFO);
  const permissions = {
    userRole,
    isAdmin,
    canFetchBalance,
    canFetchClabe,
  };
  const [{ user, account, business, auth, access }] = useApp(areEnviromentVarsLoaded, permissions);
  const { isUserAccessLoaded } = useGetUserAccess();

  useEffect(() => {
    if (user.userId) {
      TagManager.dataLayer({
        dataLayer: {
          userId: user.userId,
        },
      });
    }
  }, [user.userId]);

  // We decided to cast as any to avoid the need to cast all Stores from each project codebase.
  // This should not cause any issue with your project. Do not override this rule
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const pageProps = { user, account, business, auth, access } as any;

  // Financial Instruments
  const hasPaymentLinks = access.financialInstrument?.payment_links;

  if (!business.isFetched || !isUserAccessLoaded) {
    return <AppLoader />;
  }

  return (
    <Switch>
      {/** User Management Routes */}
      <Route path={routes.USER_ROOT}>
        <AuthRoute allowedAction={allowedActions.ACCOUNTS_SETTINGS_PAGE}>
          <UserManagement {...pageProps} variables={variables} />
        </AuthRoute>
      </Route>

      {/** Collect App Route */}
      <Route path={routes.COLLECT}>
        <AuthRoute
          allowedAction={allowedActions.PAYMENTS_PAGE}
          allowedFinancialInstrument={allowedFinancialInstruments.PAYMENT_LINKS}
        >
          <BADocumentUpload variables={variables} {...pageProps}>
            <Collect variables={variables} {...pageProps} />
          </BADocumentUpload>
        </AuthRoute>
      </Route>

      {/** Batch App Route */}
      <Route path={routes.BATCH_ROUTES.BATCH}>
        <AuthRoute allowedFinancialInstrument={allowedFinancialInstruments.ACCOUNTS_PAYABLE}>
          <Batch {...pageProps} variables={variables} />
        </AuthRoute>
      </Route>

      {/** Payment Tools Routes */}
      <Route path={routes.TOOLS}>
        <AuthRoute
          allowedAction={allowedActions.TOOLS_PAGE}
          allowedFinancialInstrument={allowedFinancialInstruments.PAYMENT_LINKS}
        >
          <Tools variables={variables} {...pageProps} />
        </AuthRoute>
      </Route>

      {/** Reconciliation Routes **/}
      <Route path={routes.RECONCILIATION}>
        <AuthRoute
          allowedAction={allowedActions.REPORTING_PAGE}
          allowedFinancialInstrument={allowedFinancialInstruments.PAYMENT_LINKS}
        >
          <Reconciliation variables={variables} {...pageProps} />
        </AuthRoute>
      </Route>

      {/** Movements Landing */}
      <Route path={routes.MOVEMENTS.LANDING}>
        <AuthRoute
          allowedAction={allowedActions.MOVEMENTS_PAGE}
          allowedFinancialInstrument={allowedFinancialInstruments.PAYMENT_LINKS}
        >
          <Movements variables={variables} {...pageProps} />
        </AuthRoute>
      </Route>

      {/** Debit Landing Routes */}
      <Route exact path={routes.LANDING}>
        {hasPaymentLinks ? (
          <DashboardPages variables={variables} {...pageProps} />
        ) : (
          <HomePage {...pageProps} />
        )}
      </Route>

      <Redirect from="*" to={{ ...history.location, pathname: routes.LANDING }} />
    </Switch>
  );
};

export default observer(Router);
