import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { ThemeProvider } from '@material-ui/core';

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mobx-react-lite/batchingForReactDom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import theme, { oysterTheme } from '@yaydoo/react-components/lib/theme';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';

import { env, gtmId, fbPixelId, sentryDSN } from '@configs/variables';

// initialize app
configure({ enforceActions: 'observed' });

declare module '@material-ui/core/styles' {
  interface Theme {
    oysterTheme: typeof oysterTheme;
  }
  interface ThemeOptions {
    oysterTheme?: typeof oysterTheme;
  }
}

TagManager.initialize({ gtmId });

ReactPixel.init(fbPixelId, undefined, {
  autoConfig: true,
  debug: false,
});

Sentry.init({
  dsn: sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: env,
  tracesSampleRate: env === 'prod' ? 0.2 : 1.0,
});

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
