const env: string = process.env.REACT_APP_ENVIRONMENT || 'prod';

const gtmId: string = process.env.REACT_APP_GTM_ID || '';
const fbPixelId = process.env.FB_PIXEL_ID || '403083323660509';
const sentryDSN =
  process.env.SENTRY_DSN ||
  'https://d9de325c7dcf45abb998e6d73fa9bc25@o204935.ingest.sentry.io/5942001';

// Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyA46rW3eq0LO8ujJpskYIfxL20h6ZZI4ho',
  authDomain: 'push-notification-ios-d4273.firebaseapp.com',
  databaseURL: 'https://push-notification-ios-d4273.firebaseio.com',
  projectId: 'push-notification-ios-d4273',
  storageBucket: 'push-notification-ios-d4273.appspot.com',
  messagingSenderId: '1026459652799',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
};

const apiTimeout = {
  time: 180000,
};

// Flags

export { env, gtmId, fbPixelId, sentryDSN, firebaseConfig, apiTimeout };
