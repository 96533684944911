import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { TUserStore } from '@yaydoo/react-mobx-stores/lib/user';

import useStyles from './styles';

export interface IHomePage {
  user: TUserStore;
}

const HomePage: FC<IHomePage> = ({ user }) => {
  const css = useStyles();
  const { fullName, email } = user;

  return (
    <Grid
      className={css.root}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography className={css.welcome} variant="h1">
          Bienvenido
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={css.fullName} variant="h2">
          {fullName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center" variant="body1" color="textSecondary">
          {email}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HomePage;
