import { useCallback } from 'react';
import useStore from '@hooks/useStore';
import AuthCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/AuthCookie';
import useEnviromentVars from '@yaydoo/react-components/lib/hooks/useEnviromentVars';

import redirectToAuthApp from '@utils/redirectToAuthApp';

interface IUseLogout {
  logoutUser: () => void;
}

function useLogout(): IUseLogout {
  const { auth } = useStore();
  const variables = useEnviromentVars();

  const logoutUser = useCallback(async () => {
    const authAppUrl = variables?.authApp.replace('stage', 'stg');
    try {
      await auth.logout().catch(() => ({}));
    } finally {
      AuthCookie.deleteCookie();
      redirectToAuthApp(authAppUrl ?? '');
    }
  }, []);

  return { logoutUser };
}

export default useLogout;
