import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IconProps } from '@yaydoo/react-components/lib/icons/types';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';

import Layout from '@yaydoo/react-components/lib/layouts/WebDashboardLayout';
import SidebarUserFooter from '@yaydoo/react-components/lib/components/SidebarUserFooter';
import SidebarNavigation from '@yaydoo/react-components/lib/components/SidebarNavigation';
import RequestMoneyIcon from '@yaydoo/react-components/lib/icons/RequestMoney';
import HomeIcon from '@yaydoo/react-components/lib/icons/Home';
import SettingIcon from '@yaydoo/react-components/lib/icons/Setting';
import MovementsIcon from '@yaydoo/react-components/lib/icons/Retreats';
import LogoutIcon from '@yaydoo/react-components/lib/icons/Logout';
import ReportIcon from '@yaydoo/react-components/lib/icons/Goods';
import useEnviromentVars from '@yaydoo/react-components/lib/hooks/useEnviromentVars';
import useFirebaseAnalytics from '@yaydoo/react-components/lib/hooks/useFirebaseAnalytics';

import AccountSwitcher from '@components/AccountSwitcher';
import { ReactComponent as BatchIcon } from '@assets/images/icons/batch.svg';
import routes from '@constants/routes';
import useStore from '@hooks/useStore';
import useLogout from '@hooks/useLogout';
import { events } from '@constants/firebaseEvents';
import { refUserTypes } from '@constants/userTypes';

import isURL from '@utils/isURL';

interface ISidebarItem {
  title: string;
  disabled: boolean;
  icon: FC<IconProps>;
  link: string;
  tooltip?: string;
  itemId?: string;
}

const defaultItems = [
  {
    title: 'Inicio',
    disabled: false,
    icon: HomeIcon,
    link: '/',
    itemId: 'btn_inicio_right_sidenav',
  },
];

const URL_FIRST_PART = /^\/[\w-]*/;

const MainLayout: FC = ({ children }) => {
  const history = useHistory();
  const variables = useEnviromentVars();

  const { logoutUser } = useLogout();
  const { user, business, access } = useStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { logEvent } = useFirebaseAnalytics();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const stay = params.get('stay');

  const [sidebarItems, setSidebarItems] = useState<ISidebarItem[]>([]);
  const [currentRoute] = stay !== null ? [`/${stay}`] : pathname?.match(URL_FIRST_PART) || ['/'];

  const canShowSwitcher = access.hasAccessActions(allowedActions.FINANCIAL_PRODUCT_SWITCHER);

  useEffect(() => {
    const items = [...defaultItems] as ISidebarItem[];
    items[0].link = '/';

    const hasTaxId =
      (!user.taxId || user.taxId.trim() === '') &&
      user.refUserType !== refUserTypes.REF_TYPE_INVITED_USER;

    // Financial Instruments
    const hasPaymentLinks = access.financialInstrument?.payment_links;
    const hasAccountsPayable = access.financialInstrument?.accounts_payable;

    if (hasPaymentLinks) {
      items.push({
        title: t('sidePane.paymentLink'),
        disabled: false,
        icon: RequestMoneyIcon,
        link: routes.COLLECT,
        tooltip: hasTaxId ? t('sidePane.noRFCPayment') : undefined,
        itemId: 'btn_cobrar_right_sidenav',
      });
    }

    if (hasAccountsPayable) {
      items.push({
        title: t('sidePane.batch'),
        disabled: false,
        icon: () => <BatchIcon />,
        link: routes.BATCH_ROUTES.BATCH,
        tooltip: undefined,
        itemId: 'btn_disperciones_right_sidenav',
      });
    }

    if (access.hasAccessActions(allowedActions.REPORTING_PAGE) && hasPaymentLinks) {
      items.push({
        title: t('sidePane.report'),
        disabled: false,
        icon: ReportIcon,
        link: routes.RECONCILIATION,
        itemId: 'btn_reporte_right_sidenav',
      });
    }

    if (access.hasAccessActions(allowedActions.MOVEMENTS_PAGE) && hasPaymentLinks) {
      items.push({
        disabled: false,
        icon: MovementsIcon,
        title: t('sidePane.movements'),
        link: routes.MOVEMENTS.LANDING,
        itemId: 'btn_tarjeta_credito_sidenav',
      });
    }

    if (access.hasAccessActions(allowedActions.TOOLS_PAGE) && hasPaymentLinks) {
      items.push({
        title: t('sidePane.tools'),
        disabled: false,
        icon: SettingIcon,
        itemId: 'btn_herramientas_right_sidenav',
        link: routes.TOOLS,
      });
    }

    setSidebarItems(items);
  }, [user.taxId, pathname, access.userRole, user.refUserType, access.financialInstrument]);

  const goToProfile = () => {
    logEvent(events.dcAccSettingsInitTap.name, events.dcAccSettingsInitTap.eventParams);
    history.push(routes.PROFILE);
  };

  const onSidebarClick = (link: string) => {
    if (!isURL(link)) {
      history.push(link);
    } else {
      window.location.href = link;
    }
  };

  return (
    <Layout
      sidebarComp={canShowSwitcher ? <AccountSwitcher /> : <div />}
      sidebarMenu={
        <SidebarNavigation items={sidebarItems} selected={currentRoute} onChange={onSidebarClick} />
      }
      leftHeaderComp={<LogoutIcon fontSize={20} defaultColor={true} onClick={logoutUser} />}
      footerComp={
        <SidebarUserFooter
          disableGoToProfile={!access.hasAccessActions(allowedActions.ACCOUNTS_SETTINGS_PAGE)}
          logout={logoutUser}
          goToProfile={goToProfile}
          logoutStr="Cerrar Sesión"
          fullName={`${user.firstName} ${user.middleName} ${user.lastName}`}
          accountType={t(`business.${business.businessTypeStr}`)}
          profilePicUrl={
            user.profilePicUrl ? `${variables.s3Assets}${user.profilePicUrl}` : undefined
          }
        />
      }
    >
      {children}
    </Layout>
  );
};

export default observer(MainLayout);
