import { useEffect, useState } from 'react';

import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';
import ClientFactory from '@yaydoo/react-mobx-stores/lib/http/factory';

import useStore from '@hooks/useStore';
import AuthCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/AuthCookie';
import CacaoOnboardedCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/CacaoOnboardedCookie';
import SecurityProfileCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/SecurityProfileCookie';

import redirectToAuthApp from '@utils/redirectToAuthApp';
import useInitEnviromentVars from '@yaydoo/react-components/lib/hooks/useInitEnviromentVars';
import Variables, { Enviroments } from '@yaydoo/react-components/lib/utils/Variables';

import { env, firebaseConfig, apiTimeout } from '@configs/variables';

interface IUseAppInit {
  token: string | null;
  enviromentVars: Variables;
  areEnviromentVarsLoaded: boolean;
}

function useAppInit(): IUseAppInit {
  const { auth } = useStore();
  const { token } = auth;
  const { enviromentVars, areEnviromentVarsLoaded } = useInitEnviromentVars(
    env as Enviroments,
    true,
    firebaseConfig,
  );
  const [isTokenRequested, toggleRequestToken] = useState(false);

  useEffect(() => {
    if (areEnviromentVarsLoaded) {
      const url = env === 'stage' ? enviromentVars.api.replace('stage', 'stg') : enviromentVars.api;

      // Paybook SAT takes more time fetch data sometimes so increasing timeout limit.
      ClientFactory.addConfig(OysterService.DEFAULT_CONNECTION, url, {
        timeout: apiTimeout.time,
      });
      // ClientFactory.addConfig('txnApi', enviromentVars.txnApi);

      AuthCookie.init(enviromentVars);
      CacaoOnboardedCookie.init({
        enviroment: enviromentVars.enviroment,
        cookieDomain: enviromentVars.cookieDomain,
        cookieName: enviromentVars.cacaoOnboardedCookieName || '',
      });
      SecurityProfileCookie.init({
        enviroment: enviromentVars.enviroment,
        cookieDomain: enviromentVars.cookieDomain,
        cookieName: enviromentVars.securityCookieName || '',
      });

      const jwt = AuthCookie.getCookie();
      auth.setToken(jwt);
      toggleRequestToken(true);
    }
  }, [areEnviromentVarsLoaded]);

  useEffect(() => {
    if (isTokenRequested && areEnviromentVarsLoaded && !token) {
      const authAppUrl =
        env === 'stage' ? enviromentVars.authApp.replace('stage', 'stg') : enviromentVars?.authApp;
      redirectToAuthApp(authAppUrl ?? '');
    }
  }, [isTokenRequested, areEnviromentVarsLoaded, token]);

  return { token, enviromentVars, areEnviromentVarsLoaded };
}

export default useAppInit;
