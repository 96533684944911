import { dashboardTS } from '@yaydoo/react.dashboard-hub-app/lib/src/translations/es';
import {
  userMgmtTs,
  common as userMgmtCommon,
} from '@yaydoo/react.user-management-hub-app/lib/src/translations/es';

import { batch } from '@yaydoo/react.batch-hub-app/lib/src/translations/es';
import { collect } from '@yaydoo/react.collect-hub-app/lib/src/translations/es';
import { transactions } from '@yaydoo/react.payment-transactions-hub-app/lib/src/translations/es';
import { reconciliation } from '@yaydoo/react.reconciliation-hub-app/lib/src/translations/es';

export default {
  logoutDialog: {
    timeoutTitle: '¿Sigues ahí?',
    timeoutText: 'La sesión se cerrará automáticamente en 1 minuto',
    timeoutLogout: 'Cerrar sesión',
    timeoutContinue: 'Sigo aquí',
  },
  common: {
    ...userMgmtCommon,
    done: 'Listo',
    fetchingError: 'No pudimos completar la acción solicitada en este momento.',
  },
  business: {
    pf: 'Persona física',
    pm: 'Persona moral',
  },
  sidePane: {
    pl: 'Perfil de pagos',
    plText: 'Pagos y cobros',
    debit: 'Perfil de efectivo',
    instantCredit: 'Préstamo instantáneo',
    lineOfCredit: 'Linea de crédito',
    debitCards: 'Tarjetas',
    obtainCredit: 'Obtén un crédito',
    paymentLink: 'Cobrar',
    batch: 'Dispersiones',
    invoincingLink: 'Pagar',
    noRFCPayment:
      'Para habilitar esta función, debes registrar tu RFC en Oyster. Contáctanos mediante el chat y con gusto te ayudaremos.',
    creditKyc: {
      pending: {
        title: 'Tu solicitud está en proceso',
      },
      needDocument: {
        title: 'Solicitud de crédito incompleta',
      },
      creditCard: 'Tarjeta de crédito',
      creditCardSummary: 'Resumen',
      creditCards: 'Tarjetas',
      requestCreditCard: 'Solicita tu tarjeta de crédito',
    },
    tools: 'Herramientas',
    movements: 'Movimientos',
    installmentLoan: {
      title: 'Installment loan',
      menu1: 'Payment schedule',
      menu2: 'Download statement',
      menu3: 'FAQ’s',
    },
    report: 'Reportes',
  },

  dashboardTS,
  userMgmtTs,
  collect,
  transactions,
  reconciliation,
  batch,
};
