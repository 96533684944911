import { ChangeEvent, FC, useState, useMemo, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import SidebarAccount from '@yaydoo/react-components/lib/components/SidebarAccount';
import Commission from '@yaydoo/react-components/lib/icons/Commission';
import RequestMoney from '@yaydoo/react-components/lib/icons/RequestMoney';
import CacaoOnboardedCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/CacaoOnboardedCookie';
import { allowedRoles } from '@yaydoo/react.ui-access-manager/lib/constants/allowedRoles';

import { formatMoney } from '@yaydoo/react-components/lib/utils/common';

import useStore from '@hooks/useStore';
import { events, IFirebaseEvent } from '@constants/firebaseEvents';
import routes from '@constants/routes';
import useStyles, { useContainerStyles, TOysterThemeColor } from './styles';
import useFirebaseAnalytics from '@yaydoo/react-components/lib/hooks/useFirebaseAnalytics';

interface IconColor {
  color?: TOysterThemeColor;
}
interface ISwitchOption {
  label: string;
  icon: FC<IconColor>;
  amount: string;
  value: number;
  link: string;
  firebaseEvent?: IFirebaseEvent;
}

type OptValue = string | number;

/* Icons for select */
export const CommissionIcon: FC<IconColor> = ({ color = 'oyster' }) => {
  const css = useStyles({ color });

  return <Commission className={css.icon} />;
};

export const BrandGreenIcon: FC = () => <CommissionIcon color="brandGreen" />;

export const PayCardIcon: FC<IconColor> = ({ color = 'peacock' }) => {
  const css = useStyles({ color });

  return <RequestMoney className={css.icon} />;
};

/* Icons for select */
const AccountSwitcher: FC = () => {
  const css = useContainerStyles();
  const history = useHistory();
  const [value, setValue] = useState<number>(0);
  const { account, business, access } = useStore();
  const { balance } = account;
  const { t } = useTranslation();
  const isDebitUser = CacaoOnboardedCookie.getValue();
  const { pathname } = useLocation();
  const { logEvent } = useFirebaseAnalytics();

  const isAdmin = access?.userRole === allowedRoles.ADMIN;
  const isAccountant = access?.userRole === allowedRoles.ACCOUNTANT;

  const options: ISwitchOption[] = useMemo(() => {
    const opts = [
      {
        label: isDebitUser && (isAdmin || isAccountant) ? t('sidePane.debit') : t('sidePane.pl'),
        icon: BrandGreenIcon,
        amount:
          isDebitUser && (isAdmin || isAccountant)
            ? formatMoney(balance?.amount || 0)
            : t('sidePane.plText'),
        value: 0,
        link: routes.LANDING,
        display: true,
        menuItemId: 'btn_debit_switcher',
        firebaseEvent: events.switcherDebitTap,
      },
    ].filter((o) => o.display);

    // setting selected dropdown on path change
    const [selectedIndex] = opts.filter((op) => {
      return op.link === pathname;
    });

    if (selectedIndex) {
      setValue(selectedIndex.value || 0);
    }

    return opts;
  }, [balance?.amount, pathname, isDebitUser, business.status]);

  const changeValue = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value: val } = e.target;
    const index = val as number;
    const selectedIndex = options.findIndex((op) => op.value === index);
    const selectedOption = options[selectedIndex];
    if (selectedOption.firebaseEvent) {
      const event = selectedOption.firebaseEvent;
      logEvent(event.name, event.eventParams);
    }
    // Changing url route
    if (options[selectedIndex]) {
      history.push(options[selectedIndex]?.link);
    }
    setValue(index);
  };

  const onOpen = () => {
    logEvent(events.switcherInitiateTap.name, events.switcherInitiateTap.eventParams);
  };

  const loadData = useCallback(async () => {
    if (!isAdmin) return undefined;

    await Promise.all([account.getBalance()]);
  }, [isAdmin, isAccountant]);

  useEffect(() => {
    if (access.userRole !== '') {
      loadData();
    }
  }, [pathname, access.userRole]);

  return (
    <section className={css.container} data-testid="accountSwitcherCont">
      <Box className={css.dropDownContainer}>
        <SidebarAccount
          disabled={!isAdmin}
          options={options}
          onChange={changeValue}
          value={value as OptValue}
          onOpen={onOpen}
        />
      </Box>
    </section>
  );
};

export default observer(AccountSwitcher);
