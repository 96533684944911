import { FC } from 'react';
import ActivityTracker from '@yaydoo/react-components/lib/components/ActivityTracker';
import { useTranslation } from 'react-i18next';

import useStore from '@hooks/useStore';

export interface ActivityTrackerWrapperProps {}

const ActivityTrackerWrapper: FC<ActivityTrackerWrapperProps> = ({ children }) => {
  const { auth } = useStore();
  const { t } = useTranslation();
  return (
    <ActivityTracker
      logout={auth.logout}
      updateTTL={auth.updateTTL}
      logoutTimeoutDifference={15}
      showModalMinutes={13}
      updateTtlMinutes={13.5}
      title={t('logoutDialog.timeoutTitle')}
      ttlUpdateText={t('logoutDialog.timeoutContinue')}
      logoutText={t('logoutDialog.timeoutLogout')}
      contentText={t('logoutDialog.timeoutText')}
    >
      {children}
    </ActivityTracker>
  );
};

export default ActivityTrackerWrapper;
