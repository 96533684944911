import { useEffect } from 'react';

import useGetUserData from '@hooks/useGetUserData';
import CacaoOnboardedCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/CacaoOnboardedCookie';
import { Enviroments } from '@yaydoo/react-components/lib/utils/Variables';
import useDebugger from '@yaydoo/react-components/lib/hooks/useDebugger';

import useStore from '@hooks/useStore';
import { IGlobalStore } from '@store/index';

import * as Sentry from '@sentry/react';
import { env } from '@configs/variables';

export type IUseApp = [store: IGlobalStore, isDebitUser: boolean];
export interface IPermissions {
  userRole: string;
  isAdmin: boolean;
  canFetchBalance: boolean;
  canFetchClabe: boolean;
}
function useApp(areEnviromentVarsLoaded: boolean, permissions: IPermissions): IUseApp {
  const store = useStore();
  const { user } = store;
  const { loadUserData, dataFetched } = useGetUserData();
  const isDebitUser = CacaoOnboardedCookie.getValue();

  useDebugger(env as Enviroments);

  useEffect(() => {
    if (areEnviromentVarsLoaded && permissions.userRole !== '') {
      loadUserData(permissions);
    }
  }, [areEnviromentVarsLoaded, permissions.userRole]);

  useEffect(() => {
    if (user.userId) {
      Sentry.setUser({ id: user.userId, email: user.email });
    }
  }, [user.userId]);

  useEffect(() => {
    if (dataFetched && !user.initialDashboardAccessAt) {
      user.updateUserInitialDashboardAccess();
    }
  }, [user.initialDashboardAccessAt, dataFetched]);

  return [store, isDebitUser];
}

export default useApp;
