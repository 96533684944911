import routes from '@constants/routes';

const redirectToAuthApp = (authApp: string): void => {
  const location = window.location.href;
  const redirectUri = encodeURIComponent(location);
  const replaceUri = `${authApp}${routes.LOGIN}?redirect_uri=${redirectUri}`;
  window.location.replace(replaceUri);
};

export default redirectToAuthApp;
