import { env as environment } from '@configs/variables';

const evLabels = {
  load: 'Load',
  view: 'View',
  initiate: 'Initiate',
  transactions: 'Transactions',
  clabe: 'Clabe',
  bank: 'Bank',
  today: 'Today',
  week: 'Week',
  month: 'Month',
  personal: 'Personal',
  org: 'Organization',
  legal: 'Legal',
  debit: 'Debit',
  lineOfCredit: 'LoC',
  instantCredit: 'IC',
};

const evActions = {
  error: 'Error',
  success: 'Success',
  tap: 'Tap',
  view: 'View',
  failure: 'Failure',
};

const evCats = {
  dcAccSettings: 'DC Acc Settings',
  switcher: 'Switcher',
};

export interface IFirebaseEvent {
  eventCategory: string;
  name: string;
  eventParams: {
    environment: string;
    eventCategory: string;
    eventLabel: string;
    eventAction: string;
  };
}

const getEventValues = (
  eventCategory: string,
  eventLabel: string,
  eventAction: string,
): IFirebaseEvent => ({
  eventCategory,
  name: `${eventCategory} ${eventLabel} ${eventAction}`,
  eventParams: {
    environment,
    eventCategory,
    eventLabel,
    eventAction,
  },
});

const events = {
  dcAccSettingsInitTap: getEventValues(evCats.dcAccSettings, evLabels.initiate, evActions.tap),
  switcherDebitTap: getEventValues(evCats.switcher, evLabels.debit, evActions.tap),
  switcherLineOfCreditTap: getEventValues(evCats.switcher, evLabels.lineOfCredit, evActions.tap),
  switcherInstantCreditTap: getEventValues(evCats.switcher, evLabels.instantCredit, evActions.tap),
  switcherInitiateTap: getEventValues(evCats.switcher, evLabels.initiate, evActions.tap),
};

export { events };
