import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import { BrowserRouter } from 'react-router-dom';
import { EnvironmentVariablesProvider } from '@yaydoo/react-components/lib/components/EnvironmentVarsWrapper';
import { ToastProvider } from '@yaydoo/react-components/lib/components/ToastWrapper/ToastContext';
import { PermissionsProvider } from '@yaydoo/react.ui-access-manager/lib/access/Permissions';

import { store } from '@store/index';
import { StoreContext } from '@hooks/useStore';
import ActivityTrackerWrapper from '@components/ActivityTrackerWrapper';
import Router from './router';

import MainLayout from './layouts/MainLayout';
import AppLoader from '@yaydoo/react-components/lib/components/AppLoaders/Icons';

import i18nInit, { addI18nNamespace } from '@yaydoo/react-components/lib/utils/initI18n';
import useStore from '@hooks/useStore';
import useAppInit from '@hooks/useAppInit';
import es from '@translations/es';
import en from '@translations/en';

// Initialize translation
i18nInit();

// Add default namespace in i18n
addI18nNamespace('es', 'translation', es);
addI18nNamespace('en', 'translation', en);

const AppContainer: FC = () => {
  const { token, enviromentVars, areEnviromentVarsLoaded } = useAppInit();
  const { access } = useStore();

  return (
    <BrowserRouter>
      <StoreContext.Provider value={store}>
        {!token ? (
          <AppLoader />
        ) : (
          <EnvironmentVariablesProvider variables={enviromentVars}>
            <ActivityTrackerWrapper>
              <PermissionsProvider
                accessActions={toJS(access.accessActions)}
                accessPages={toJS(access.accessPages)}
                accessRole={toJS(access.userRole)}
                accessFinancialInstruments={{ ...access.financialInstrument }}
              >
                <MainLayout>
                  <ToastProvider>
                    <Router
                      areEnviromentVarsLoaded={areEnviromentVarsLoaded}
                      variables={enviromentVars}
                    />
                  </ToastProvider>
                </MainLayout>
              </PermissionsProvider>
            </ActivityTrackerWrapper>
          </EnvironmentVariablesProvider>
        )}
      </StoreContext.Provider>
    </BrowserRouter>
  );
};

export default observer(AppContainer);
