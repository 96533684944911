import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export type TOysterThemeColor = keyof Theme['oysterTheme'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: (props: { color: TOysterThemeColor }) => ({
      backgroundColor: theme.oysterTheme[props.color],
      color: 'white',
      fontSize: '2.5em',
      padding: '0.2em',
      borderRadius: 10,
    }),
  }),
);

export const useContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      marginLeft: 0,
      marginRight: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    dropDownContainer: {
      zIndex: 1,
      maxWidth: 300,
      width: '100%',
      marginBottom: '-1em',
    },
  }),
);

export default useStyles;
