import { Instance, types } from 'mobx-state-tree';

import Common from '@yaydoo/react-mobx-stores/lib/common';

const TestStore = types
  .compose(
    Common,
    types.model({
      flag: false,
    }),
  )
  .views((self) => ({
    get flagStr() {
      return `Flag: ${self.flag}`;
    },
  }))
  .actions((self) => ({
    toggleFlag: () => {
      self.flag = !self.flag;
    },
  }));

export type TTestStore = Instance<typeof TestStore>;
export default TestStore;
