import { useState, useCallback, useEffect } from 'react';

import useCommonStore from '@hooks/useStore';

export interface IUseGetUserAccess {
  isUserAccessLoaded: boolean;
  toggleIsUserAccessLoaded: (value: boolean) => void;
  getUserAccess: () => void;
}

function useGetUserAccess(): IUseGetUserAccess {
  const [isUserAccessLoaded, toggleIsUserAccessLoaded] = useState(false);
  const { access } = useCommonStore();

  const getUserAccess = useCallback(async () => {
    toggleIsUserAccessLoaded(false);
    try {
      await access.getUserAccess();
    } catch {
      //
    }
    toggleIsUserAccessLoaded(true);
  }, []);

  useEffect(() => {
    getUserAccess();
  }, []);

  return { isUserAccessLoaded, toggleIsUserAccessLoaded, getUserAccess };
}

export default useGetUserAccess;
